import React from 'react'
import logo_dark from '../../assets/images/logo-dark.png';
import logo_light from '../../assets/images/logo-light.png';
import '../../assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '../../assets/css/tailwind.css';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'

export default function Navbar(props) {
    let { navClass } = props;
    // eslint-disable-next-line
    let [manu,setManu] = useState('');
    let location = useLocation();

    useEffect(() => {
        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)

        function windowScroll() {
            const navbar = document.getElementById("topnav");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("nav-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("nav-sticky");
                }
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0)
        return () => {
            window.removeEventListener('scroll', windowScroll);
        };
// eslint-disable-next-line
    }, [location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])

    return (
        <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? '' : navClass === "nav-sticky" ?
            'bg-white dark:bg-slate-900' : ''}`}>
            <div className="container relative">
                {
                    navClass === "nav-light" ?
                        <Link className="logo" to="/index">
                            <span className="inline-block dark:hidden">
                                <img src={logo_dark} className="l-dark" height="24" alt="" />
                                <img src={logo_light} className="l-light" height="24" alt="" />
                            </span>
                            <img src={logo_light} height="24" className="hidden dark:inline-block" alt="" />
                        </Link>
                        :
                        <Link className="logo" to="/index">
                            <img src={logo_dark} className="inline-block dark:hidden" alt="" />
                            <img src={logo_light} className="hidden dark:inline-block" alt="" />
                        </Link>
                }
            </div>
        </nav >
    )
}
