import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import IndexWebProgramming from './pages/index/index-web-programming';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<IndexWebProgramming/>} />
            </Routes>
        </BrowserRouter>
    )
}
